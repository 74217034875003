import React from 'react'
import { Link } from 'react-router-dom'
import DashboardNav from './DashboardNav'
import Navbar from './Navbar'
import Footer from './Footer'
import base from "../img/base.jpg"
import base2 from "../img/base2.png"
import base3 from "../img/base3.PNG"

const MyInvestments = () => {
  if(sessionStorage.getItem("user") !== null) {
    return (
        <div className="bg-gray-200">
              <DashboardNav />
              <div style={{backgroundImage: `url(${base2})`}} className="p-20">
                <p className="font-semibold text-white text-3xl">Invest Statistics</p>
              </div>
              <div style={{backgroundImage: `url(${base})`}} className="p-20 flex flex-col justify-between items-center">
                <div className="flex lg:flex-row flex-col justify-between items-center lg:space-x-10 space-x-0 lg:space-y-0 space-y-10">
                    <div className="bg-black py-14 lg:px-10 px-5 rounded-md w-auto container  border border-solid border-slate-400 text-white text-start">
                        <div className="flex flex-row justify-between items-center space-x-20 p-3">
                            <div className="text-end">
                                <p className="font-medium">Total Invest</p>
                                <p className="font-semibold text-xl">$0.00</p>
                                <Link to="/investment" className='bg-customYellow rounded flex flex-row justify-between items-center space-x-2 py-3 px-4 mt-5 text-black'>
                                  <p className='hover:font-semibold'>Invest Now</p>
                                  <i class="las la-arrow-right"></i>
                                </Link>
                            </div>
                            <div className="text-end">
                                <p className="font-medium">Total Profit</p>
                                <p className="font-semibold text-xl">$0.00</p>
                                <Link to="/withdraw" className='bg-customYellow rounded flex flex-row justify-between items-center space-x-2 py-3 px-4 mt-5 text-black'>
                                  <p className='hover:font-semibold'>Withdraw Now</p>
                                  <i class="las la-arrow-right"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="bg-black py-28 px-28 rounded-md w-auto container mb-20 border border-solid border-slate-400">
                        <p className="font-medium text-xl text-white">No Investment Found Yet</p>
                    </div>
                </div>
                <div className="mt-20 lg:w-full w-80 lg:overflow-x-hidden overflow-x-scroll">
                    <table>
                      <thead className="bg-customYellow text-white">
                        <tr>
                          <th className="rounded-tl-md p-2">Plan</th>
                          <th>Return</th>
                          <th></th>
                          <th>Received</th>
                          <th className="rounded-tr-md p-2">Next payment</th>
                        </tr>
                      </thead>
                      <tbody className="bg-black text-white shadow shadow-customYellow">
                        <tr>
                            <td className="px-32"></td>
                            <td className="px-20"></td>
                            <td className="px-10 py-4">Data not found</td>
                            <td className="px-28"></td>
                            <td className="px-20"></td>
                        </tr>
                      </tbody>
                    </table>
                </div>
              </div>
              <Footer />
        </div>
      )
  } else {
    return(
        <div>
            <Navbar />
            <div style={{backgroundImage: `url(${base})`}} className="flex flex-col justify-between items-center">
                <p className="text-3xl font-medium text-customYellow my-20">Please Sign In or Register</p>
            </div>
            <Footer />
        </div>
    )
  }
}

export default MyInvestments