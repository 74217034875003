import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from './Navbar'
import Footer from './Footer'
import forex from '../img/forex.jpg'
import ex1 from '../img/ex1.svg'
import ex2 from '../img/ex2.svg'
import ex3 from '../img/ex3.svg'
import crypto from '../img/cryptocurrency.PNG'
import estate from '../img/estate.jpg'
import certi from '../img/certi.png'
import icon1 from '../img/icon-line-fund.png'
import icon2 from '../img/icon-line-more.png'
import icon3 from '../img/icon-line-scam.png'
import icon4 from '../img/icon-line-decentralized.png'
import avatar1 from '../img/avatar-1.png'
import avatar2 from '../img/avatar-2.png'
import avatar3 from '../img/avatar-3.png'
import avatar4 from '../img/avatar-4.png'
import services from "../vid/services.mp4"
import { Carousel } from '@trendyol-js/react-carousel'

const Home = () => {
  return (
    <div>
      <Navbar />
      {/* Hero */}
      <section id='hero h-full'>
        <div id='overlay' className='lg:block absolute hidden opacity-70 w-full h-full bg-black' style={{height: 759}}>
        </div>
        <div id='overlay' className='lg:hidden absolute block opacity-70 w-full h-full bg-black' style={{height: 232}}>
        </div>
        <div className='relative lg:top-40 top-10'>
          <div className='absolute flex flex-col justify-between items-center container mx-auto lg:px-0 px-8'>
            <h1 className='text-white'>
              <p className='border-l-4 border-dotted border-customYellow lg:text-5xl text-2xl pl-3 font-medium'>Invest in Bitcoin with the</p>
              <p className='border-l-4 border-dotted border-customYellow lg:text-5xl text-2xl pl-3 font-medium'>leading social investment</p>
              <p className='border-l-4 border-dotted border-customYellow lg:text-5xl text-2xl pl-3'>network!</p>
              <p className='text-gray-400 font-medium text-xl mt-4 lg:block hidden'>Join hundreds of thousands of traders who have already learned the more effective <br /> strategies for investing in Bitcoin, USDT And Ethereum</p>
              <br />
              <Link to="/register" className='border border-solid border-customYellow rounded-full text-white py-2 px-4 hover:font-bold hover:border-2'>Get Started</Link>
            </h1>
          </div>
        </div>
        <img src={forex} alt='' className='w-full lg:h-1/2'/>
      </section>
      {/* About */}
      <section id='about' style={{background: "linear-gradient(90deg, rgba(204,163,84,1) 80%, rgba(52,41,21,1) 100%)"}}>
        <div className='flex flex-col justify-between items-center'>
          {/*<div className='bg-black my-20 rounded p-3 px-10 lg:w-8/12 w-11/12 flex flex-col justify-between items-center'>
            <video src={vid} controls autoplay loop></video>
          </div>*/}
          <div className='flex lg:flex-row flex-col justify-around items-center container mx-auto mb-20 lg:px-0 px-10 mt-14'>
            <div className='bg-customBlack shadow px-4 py-10 rounded text-white lg:w-1/2 w-80'>
              <h1 className='text-3xl font-semibold'>About Us</h1>
              <br />
              <p>Activated Crypto Company is a duly registered company established with the aim of rendering endless services of maximizing profits via Bitcoin trading.</p>
              <br />
              <p>The excellent performance demonstrated by the Company together with significantly increased assets have allowed the Company to reach out to everyone interested in profitable investment and getting stable income within a short period of time. The Company employs experts on the full-time basis.</p>
            </div>
            <div className="lg:mt-0 mt-10">
              <div className='flex flex-row justify-between items-center space-x-3'>
                <div className='bg-customBlack p-3 lg:w-20 lg:h-20 w-30 h-30 rounded-full flex flex-col justify-center items-center'>
                  <img src={ex1} alt="" width={40}/>
                </div>
                <div className='text-white'>
                  <h1 className='font-bold mb-1 text-2xl'>Create an account</h1>
                  <p className="hidden lg:block">Sign up for an account with your name, <br /> email and phone number.</p>
                  <p className="block lg:hidden">Sign up for an account with your name, email and phone number.</p>
                </div>
              </div>
              <div className='flex flex-row justify-between items-center space-x-3 mt-7'>
                <div className='bg-customBlack p-3 lg:w-20 lg:h-20 w-30 h-30 rounded-full flex flex-col justify-center items-center'>
                  <img src={ex2} alt="" width={40}/>
                </div>
                <div className='text-white'>
                  <h1 className='font-bold mb-1 text-2xl'>Deposit into your account</h1>
                  <p className="hidden lg:block">Using any available cryptocurrency of <br /> your choice, setup your first plan.</p>
                  <p className="block lg:hidden">Using any available cryptocurrency of your choice, setup your first plan.</p>
                </div>
              </div>
              <div className='flex flex-row justify-between items-center space-x-3 mt-7'>
                <div className='bg-customBlack p-3 lg:w-20 lg:h-20 w-30 h-30 rounded-full flex flex-col justify-center items-center'>
                  <img src={ex3} alt="" width={40}/>
                </div>
                <div className='text-white'>
                  <h1 className='font-bold mb-1 text-2xl'>Watch your money grow</h1>
                  <p className="hidden lg:block">Sit back, relax & let your money work for <br /> you all day, everyday.</p>
                  <p className="block lg:hidden">Sit back, relax & let your money work for you all day, everyday.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Choose */}
      <section id="choose" className='bg-customBlack py-28 px-10 '>
        <div className='flex lg:flex-row flex-col justify-between items-center'>
          <div>
            <h1 className="text-white font-medium text-2xl mb-14">Why Choose Us?</h1>
            <ul className="list-disc text-customYellow">
                <li className="mb-7">
                    <p className="font-semibold text-xl text-white mb-4">Competent Professionals</p>
                    <p className="text-slate-400">We work in an atmosphere of trust and camaraderie, where <br/> partners help each other.</p>
                </li>
                <li className="mb-7">
                    <p className="font-semibold text-xl text-white mb-4">Superior Service</p>
                    <p className="text-slate-400">We are committed to providing clients with the best value and <br/> service in the industry.</p>
                </li>
                <li className="mb-7">
                    <p className="font-semibold text-xl text-white mb-4">Competitive Pricing</p>
                    <p className="text-slate-400">Providing value through straightforward commissions and <br/> added services.</p>
                </li>
            </ul>
          </div>
          <div className='bg-neutral-900 shadow px-4 py-10 rounded text-white lg:w-1/2 w-auto'>
              <h1 className='text-2xl font-semibold'>Together, we can enable you achieve even <br/> more.</h1>
              <br />
              <p>Activated Crypto leads the industry in wealth management. Our independent RIA and broker services are powered by over 10 years of industry experience.</p>
          </div>
        </div>
      </section>
      { /*Our Services*/ }
      <section className="py-10 bg-gray-200">
        <div className="flex flex-col justify-between items-center relative lg:-top-40 -top-28">
            <h1 className="font-medium text-2xl mb-7 text-white">Our <span className="text-customYellow">Services</span></h1>
            <div className="flex lg:flex-row flex-col justify-between items-center lg:space-x-4 space-x-0 lg:space-y-0 space-y-10">
                <div className="flex flex-col justify-between items-center">
                    {/* <img src={crypto} alt="" className="w-96"/> */}
                    <video src={services} controls className="w-full rounded-xl"></video>
                    {/* <div className="px-4 bg-white shadow w-96">
                        <h1 className="font-semibold my-4 text-xl">Cryptocurrency</h1>
                        <p className="font-medium mb-4">Cryptocurrencies are sets of software protocols for generating digital tokens and for tracking transactions in a way that makes it hard to counterfeit or re-use tokens.</p>
                        <div className="flex flex-col justify-between items-center mb-10">
                            <button className='bg-customYellow rounded-full flex flex-row justify-between items-center space-x-2 text-white py-2 px-4'>
                              <i class="las la-play-circle la-2x"></i>
                              <p className='font-semibold hover:font-bold'>Learn more</p>
                            </button>
                        </div>
                    </div> */}
                </div>
                {/* <div className="flex flex-col justify-between items-center">
                    <img src={forex} alt="" className="w-96"/>
                    <div className="px-4 bg-white shadow w-96">
                        <h1 className="font-semibold my-4 text-xl">Forex Trading</h1>
                        <p className="font-medium mb-4">Forex or FX is an acronym of Foreign Exchange and Forex trading means to trade on this market. Trading takes place by predicting how one currency performs against another one.</p>
                        <div className="flex flex-col justify-between items-center mb-10">
                            <button className='bg-customYellow rounded-full flex flex-row justify-between items-center space-x-2 text-white py-2 px-4'>
                              <i class="las la-play-circle la-2x"></i>
                              <p className='font-semibold hover:font-bold'>Learn more</p>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col justify-between items-center">
                    <img src={estate} alt="" className="w-96"/>
                    <div className="px-4 bg-white shadow w-96">
                        <h1 className="font-semibold my-4 text-xl">Real Estate</h1>
                        <p className="font-medium mb-4">Real estate investing, even on a very small scale, remains a tried and true means of building an individual's cash flow and wealth.</p>
                        <div className="flex flex-col justify-between items-center mb-10">
                            <button className='bg-customYellow rounded-full flex flex-row justify-between items-center space-x-2 text-white py-2 px-4'>
                              <i class="las la-play-circle la-2x"></i>
                              <p className='font-semibold hover:font-bold'>Learn more</p>
                            </button>
                        </div>
                    </div>
                </div> */}
            </div>
            <div className="mt-20">
                <h1 className="text-gray-700 lg:text-3xl text-xl text-center font-medium mb-7">COMPANY CERTIFICATE</h1>
                <div className="flex flex-col justify-between items-center lg:px-0 px-5">
                    <img src={certi} alt="" />
                </div>
            </div>
        </div>
      </section>
      {/*Other*/}
      <section className="bg-black lg:p-28 p-10">
        <div className="flex lg:flex-row flex-col justify-between items-center lg:space-x-7 space-x-0 lg:space-y-0 space-y-10 lg:mb-20 mb-10">
            <div className="flex flex-row justify-between items-center space-x-4">
                <img src={icon1} alt="" />
                <div>
                    <h1 className="text-white font-bold text-xl">Fund administration</h1>
                    <p className="text-slate-400">The fund is started in literally several minutes with minimal costs using blockchain-based infrastructure that supports.</p>
                </div>
            </div>
            <div className="flex flex-row justify-between items-center space-x-4">
                <img src={icon3} alt="" />
                <div>
                    <h1 className="text-white font-bold text-xl">Strong Security</h1>
                    <p className="text-slate-400">Our system's authentication, cold storage, smart backup, and manual withdrawals all ensure your funds remain safe and secure.</p>
                </div>
            </div>
        </div>
        <div className="flex lg:flex-row flex-col justify-between items-center lg:space-x-7 space-x-0 lg:space-y-0 space-y-10 mb-4">
            <div className="flex flex-row justify-between items-center space-x-4">
                <img src={icon4} alt="" />
                <div>
                    <h1 className="text-white font-bold text-xl">Market Research</h1>
                    <p className="text-slate-400">Assessment of viability, stability, and profitability of an investment, a sub-business, or project.</p>
                </div>
            </div>
            <div className="flex flex-row justify-between items-center space-x-4">
                <img src={icon2} alt="" />
                <div>
                    <h1 className="text-white font-bold text-xl">And much more</h1>
                    <p className="text-slate-400">Large portfolios are hard to manage because of low liquidity, slippage and high transaction costs.</p>
                </div>
            </div>
        </div>
      </section>
      {/*Affordable Packages*/}
      <section className="bg-gray-200 py-20">
        <div className="flex flex-col justify-between items-center">
            <h1 className="text-customBlack text-3xl font-medium">Affordable <span className="text-customYellow">Packages</span></h1>
            <br />
            <p className="text-gray-500 font-medium hidden lg:block">CHOOSE YOUR PREFERABLE PLAN FOR INVESTMENT</p>
            <p className="text-gray-500 font-medium lg:hidden block">CHOOSE YOUR PREFERABLE PLAN <br/> FOR INVESTMENT</p>
            <div className="rounded-md bg-black flex lg:flex-row flex-col justify-between items-center mt-14 lg:space-x-10 space-x-0 lg:pr-20 pr-0 lg:w-8/12 w-auto">
                <div className="border-4 border-solid border-gray-600 rounded p-4">
                    <p className="text-slate-400">Package 1</p>
                    <p className="text-customYellow my-4 font-medium text-xl">Bronze</p>
                    <p className="text-white font-medium">2.25% Everyday</p>
                    <p className="text-white font-medium">Total 2.25% + Capital</p>
                </div>
                <div className="lg:p-4 py-4">
                    <p className="text-slate-400">Price</p>
                    <p className="text-slate-400 my-4 font-medium text-xl">IN USDT</p>
                    <p className="text-slate-400 font-medium">2.25% ROI</p>
                </div>
                <div className="p-4">
                    <p className="text-customYellow my-4 font-medium text-xl">$50 <span className="text-white">-</span> $3,999</p>
                </div>
            </div>
            <div className="rounded-md bg-black flex lg:flex-row flex-col justify-between items-center mt-14 lg:space-x-10 space-x-0 lg:pr-20 pr-0 lg:w-8/12 w-auto">
                <div className="border-4 border-solid border-gray-600 rounded p-4 lg:w-auto w-full">
                    <p className="text-slate-400">Package 2</p>
                    <p className="text-customYellow my-4 font-medium text-xl">Silver plan</p>
                    <p className="text-white font-medium">2.25% Everyday</p>
                    <p className="text-white font-medium">Total 5% + Capital</p>
                </div>
                <div className="lg:p-4 py-4">
                    <p className="text-slate-400">Price</p>
                    <p className="text-slate-400 my-4 font-medium text-xl">IN USDT</p>
                    <p className="text-slate-400 font-medium">5% ROI</p>
                </div>
                <div className="p-4">
                    <p className="text-customYellow my-4 font-medium text-xl">$4,000 <span className="text-white">-</span> $30,000</p>
                </div>
            </div>
            <div className="rounded-md bg-black flex lg:flex-row flex-col justify-between items-center mt-14 lg:space-x-10 space-x-0 lg:pr-20 pr-0 lg:w-8/12 w-auto">
                <div className="border-4 border-solid border-gray-600 rounded p-4 lg:w-auto w-full">
                    <p className="text-slate-400">Package 3</p>
                    <p className="text-customYellow my-4 font-medium text-xl">Gold</p>
                    <p className="text-white font-medium">2.55% Everyday</p>
                    <p className="text-white font-medium">Total 7.65% + Capital</p>
                </div>
                <div className="lg:p-4 py-4">
                    <p className="text-slate-400">Price</p>
                    <p className="text-slate-400 my-4 font-medium text-xl">IN USDT</p>
                    <p className="text-slate-400 font-medium">7.65% ROI</p>
                </div>
                <div className="p-4">
                    <p className="text-customYellow my-4 font-medium text-xl">$30,000 <span className="text-white">-</span> $100,00</p>
                </div>
            </div>
            <div className="rounded-md bg-black flex lg:flex-row flex-col justify-between items-center mt-14 lg:space-x-10 space-x-0 lg:pr-20 pr-0 lg:w-8/12 w-auto">
                <div className="border-4 border-solid border-gray-600 rounded p-4 lg:w-auto w-full">
                    <p className="text-slate-400">Package 4</p>
                    <p className="text-customYellow my-4 font-medium text-xl">Long term</p>
                    <p className="text-white font-medium">3% Everyday</p>
                    <p className="text-white font-medium">Total 90% + Capital</p>
                </div>
                <div className="lg:p-4 py-4">
                    <p className="text-slate-400">Price</p>
                    <p className="text-slate-400 my-4 font-medium text-xl">IN USDT</p>
                    <p className="text-slate-400 font-medium">90% ROI</p>
                </div>
                <div className="p-4">
                    <p className="text-customYellow my-4 font-medium text-xl">$100,000 <span className="text-white">-</span> $1,000,000</p>
                </div>
            </div>
            <div className="rounded-md bg-black flex lg:flex-row flex-col justify-between items-center mt-14 lg:space-x-10 space-x-0 lg:pr-20 pr-0 lg:w-8/12 w-auto">
                <div className="border-4 border-solid border-gray-600 rounded p-4 lg:w-auto w-full">
                    <p className="text-slate-400">Package 5</p>
                    <p className="text-customYellow my-4 font-medium text-xl">Economies</p>
                    <p className="text-white font-medium">3.2% Everyday</p>
                    <p className="text-white font-medium">Total 3.2% + Capital</p>
                </div>
                <div className="lg:p-4 py-4">
                    <p className="text-slate-400">Price</p>
                    <p className="text-slate-400 my-4 font-medium text-xl">IN USDT</p>
                    <p className="text-slate-400 font-medium">3.2% ROI</p>
                </div>
                <div className="p-4">
                    <p className="text-customYellow my-4 font-medium text-xl">$150,000 <span className="text-white">-</span> $10,000,000</p>
                </div>
            </div>
            <Link to="/register" className='bg-customYellow rounded-full flex flex-row justify-between items-center space-x-2 text-customBlack p-4 mt-14 font-semibold'>
              <i class="las la-user-plus"></i>
              <p className='hover:font-bold'>Sign up Now</p>
            </Link>
        </div>
      </section>
      {/*Reviews*/}
      <section className='bg-customYellow py-28 lg:px-28 px-10 flex lg:flex-row flex-col justify-between items-center lg:space-y-0 space-y-10'>
        <div className="text-white">
            <h1 className="font-bold text-3xl mb-4 lg:block hidden">Reviews <br /> from our happy investors</h1>
            <h1 className="font-bold text-2xl mb-4 lg:hidden block">Reviews from our happy investors</h1>
            <p className="font-medium">Find reviews written by customer for customer to help you choose the best service for you, first hand experience.</p>
        </div>
        <div className='lg:w-1/2 w-96 rounded-md bg-white'>
          <Carousel
          show={1} slide={1} swiping={true} useArrowKeys={true}
          rightArrow={<i class="las la-angle-right la-4x hover:cursor-pointer mt-40 text-customYellow"></i>}
          leftArrow={<i class="las la-angle-left la-4x hover:cursor-pointer mt-40 text-customYellow"></i>}
          className='text-start'>
            <div>
                <div className="flex flex-col justify-between items-start">
                    <h1 className="font-extrabold text-4xl text-customYellow mt-10">"</h1>
                    <p className="font-medium text-gray-600">Activated Crypto is really the Best, this is my Third month with them an am really happy working with them.</p>
                    <div className="flex flex-row justify-between items-center space-x-4 my-10 px-20">
                        <img src={avatar1} alt="" className="rounded-full"/>
                        <p className="font-semibold">Loretta Paul</p>
                    </div>
                </div>
            </div>
            <div>
                <div className="flex flex-col justify-between items-start">
                    <h1 className="font-extrabold text-4xl text-customYellow mt-10">"</h1>
                    <p className="font-medium text-gray-600">I'm from South Africa, My Trading on Activated Crypto has been Easy because of their Advanced Trading Equipment which made Forex and Bitcoin Easy for Me</p>
                    <div className="flex flex-row justify-between items-center space-x-4 my-10 px-20">
                        <img src={avatar2} alt="" className="rounded-full"/>
                        <p className="font-semibold">Oliver Alfaneti</p>
                    </div>
                </div>
            </div>
            <div>
                <div className="flex flex-col justify-between items-start">
                    <h1 className="font-extrabold text-4xl text-customYellow mt-10">"</h1>
                    <p className="font-medium text-gray-600">I almost gave up on crypto investments because I was scammed many times. I got pissed off with Schemextra after they scammed me and made me lose extra money. With Activated Crypto, i am 100miles away from being scammed. Thank you Activated Crypto.</p>
                    <div className="flex flex-row justify-between items-center space-x-4 my-10 px-20">
                        <img src={avatar3} alt="" className="rounded-full"/>
                        <p className="font-semibold">Robert Keeler</p>
                    </div>
                </div>
            </div>
            <div>
                <div className="flex flex-col justify-between items-start">
                    <h1 className="font-extrabold text-4xl text-customYellow mt-10">"</h1>
                    <p className="font-medium text-gray-600">Activated Crypto made trading and Mining easy - especially for beginners like me. I've learnt so much through their Education Package and i highly rate the tools they make available to their traders, I like the Activated Crypto Dashboard with its detailed Economic Calendar of Events and expected market impact....</p>
                    <div className="flex flex-row justify-between items-center space-x-4 my-10 px-20">
                        <img src={avatar4} alt="" className="rounded-full"/>
                        <p className="font-semibold">Mary Gerald</p>
                    </div>
                </div>
            </div>
            <div>
                <div className="flex flex-col justify-between items-start">
                    <h1 className="font-extrabold text-4xl text-customYellow mt-10">"</h1>
                    <p className="font-medium text-gray-600">Activated Crypto really are the Expert Broker! I find their trading platform user-friendly and best of all their representatives really know what they're taking about. I value the personal touch from my Personal broker who has Taught me how to analyse the market and actually win trades! Five Stars to Activated Crypto</p>
                    <div className="flex flex-row justify-between items-center space-x-4 my-10 px-20">
                        <img src={avatar1} alt="" className="rounded-full"/>
                        <p className="font-semibold">Arthur Loyd</p>
                    </div>
                </div>
            </div>
            <div>
                <div className="flex flex-col justify-between items-start">
                    <h1 className="font-extrabold text-4xl text-customYellow mt-10">"</h1>
                    <p className="font-medium text-gray-600">I have tried other brokers in the past, and can honestly say Activated Crypto is a hassle- free broker. I especially like that their regulation is in New Zealand, unlike the majority. I know my money is safe and secure, most importantly, I've never had any Issues with Withdrawals or Supports</p>
                    <div className="flex flex-row justify-between items-center space-x-4 my-10 px-20">
                        <img src={avatar2} alt="" className="rounded-full"/>
                        <p className="font-semibold">Nerd Johnson</p>
                    </div>
                </div>
            </div>
            <div>
                <div className="flex flex-col justify-between items-start">
                    <h1 className="font-extrabold text-4xl text-customYellow mt-10">"</h1>
                    <p className="font-medium text-gray-600">It has been a pleasure to work with Activated Crypto and their team of investment experts. They offered me a quick and easy solution to my business investment strategy that will surely help me in handling my future spendings. I’m glad to cooperate with this qualified team.</p>
                    <div className="flex flex-row justify-between items-center space-x-4 my-10 px-20">
                        <img src={avatar3} alt="" className="rounded-full"/>
                        <p className="font-semibold">Andy Smith</p>
                    </div>
                </div>
            </div>
          </Carousel>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default Home