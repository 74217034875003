import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import DashboardNav from './DashboardNav'
import Navbar from './Navbar'
import Footer from './Footer'
import base from "../img/base.jpg"
import base2 from "../img/base2.png"
import base3 from "../img/base3.PNG"

const DepositHistory = () => {
  const tableBody = useRef()
  useEffect(() => {
    let body = sessionStorage.getItem("user")
    fetch("/api/getUploads", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          "Accept": "application/json, text/plain, */*"
        },
        body
      })
      .then((res) => {
        return res.json()
      })
      .then((data) => {
        console.log(data)
        data.uploads.forEach(upload => {
            let details = JSON.parse(upload.details)
            let date = new Date(upload.initiated)
            tableBody.current.innerHTML += `
                <tr>
                    <th scope="row" class="px-6 py-4 font-medium whitespace-nowrap text-center">
                        ${details.gateway}
                    </th>
                    <td class="px-6 py-4 text-center">
                        ${date.toDateString()}
                    </td>
                    <td class="px-6 py-4 text-center">
                       $ ${details.amount}
                    </td>
                    <td class="px-6 py-4 text-center">
                        1 USD = 1 $
                    </td>
                    <td class="px-6 py-4 text-center">
                        <lord-icon
                            src="https://cdn.lordicon.com/cgdkjbxe.json"
                            trigger="loop"
                            delay="1000"
                            colors="primary:#cca354,secondary:#141414"
                            style={{width:50, height:50}}>
                        </lord-icon>
                    </td>
                </tr>
            `
        })
      })
      .catch(err => console.log(err))
  })
  if(sessionStorage.getItem("user") !== null) {
    return (
        <div className="bg-gray-200">
              <DashboardNav />
              <div style={{backgroundImage: `url(${base2})`}} className="p-20">
                <p className="font-semibold text-white text-3xl">Deposit History</p>
              </div>
              <div style={{backgroundImage: `url(${base})`}} className="p-20 flex flex-col justify-between items-center">
                <div class="relative w-full lg:overflow-x-hidden overflow-x-scroll">
                    <table class="w-full">
                        <thead class="text-white bg-customYellow">
                            <tr>
                                <th scope="col" class="px-6 py-3 rounded-tl-md text-center">
                                    Gateway | Transaction
                                </th>
                                <th scope="col" class="px-6 py-3 text-center">
                                    Initiated
                                </th>
                                <th scope="col" class="px-6 py-3 text-center">
                                    Amount
                                </th>
                                <th scope="col" class="px-6 py-3 text-center">
                                    Conversion
                                </th>
                                <th scope="col" class="px-6 py-3 rounded-tr-md text-center">
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody ref={tableBody} className="bg-black text-white">
                        </tbody>
                    </table>
                </div>
              </div>
              <Footer />
        </div>
      )
  } else {
    return(
        <div>
            <Navbar />
            <div style={{backgroundImage: `url(${base})`}} className="flex flex-col justify-between items-center">
                <p className="text-3xl font-medium text-customYellow my-20">Please Sign In or Register</p>
            </div>
            <Footer />
        </div>
    )
  }

}

export default DepositHistory