import React, { useEffect, useLayoutEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets"
import DashboardNav from './DashboardNav'
import Navbar from './Navbar'
import Footer from './Footer'
import base from "../img/base.jpg"
import base2 from "../img/base2.png"
import base3 from "../img/base3.PNG"

const Dashboard = () => {
  const [loadData, setLoadData] = useState({})
  const interest = useRef()
  useEffect(()=> {
       let data = {
        username: JSON.parse(sessionStorage.getItem("user")).username
       }
        fetch("/api/getDashboard", {
            method: "POST",
            headers: {
              "Content-type": "application/json",
              "Accept": "application/json, text/plain, */*"
            },
            body: JSON.stringify(data)
          })
          .then((res) => {
            return res.json()
          })
          .then((data) => {
            let len = data.dashboard[0].interestWalletBalance.split("").length
            let text1 = ""
            let text2 = ""
            for(let i = 0; i <= len-1; i++) {
               if(i == len - 1) {
                text2 += data.dashboard[0].interestWalletBalance.split("")[i]
               } else {
                    text1 += data.dashboard[0].interestWalletBalance.split("")[i]
                    text2 += data.dashboard[0].interestWalletBalance.split("")[i]
               }
            }
            setLoadData({
                depositWalletBalance: data.dashboard[0].depositWalletBalance,
                interestWalletBalance: data.dashboard[0].interestWalletBalance,
                totalInvest: data.dashboard[0].totalInvest,
                totalDeposit: data.dashboard[0].totalDeposit,
                totalWithdraw: data.dashboard[0].totalWithdraw,
                referralEarnings: data.dashboard[0].referralEarnings,
            })
            setInterval(()=> {
                setTimeout(() => {
                    interest.current.style.color = "green"
                    setLoadData({
                        depositWalletBalance: data.dashboard[0].depositWalletBalance,
                        interestWalletBalance: text2,
                        totalInvest: data.dashboard[0].totalInvest,
                        totalDeposit: data.dashboard[0].totalDeposit,
                        totalWithdraw: data.dashboard[0].totalWithdraw,
                        referralEarnings: data.dashboard[0].referralEarnings,
                    })
                }, 2000)
                setTimeout(()=> {
                    setLoadData({
                        depositWalletBalance: data.dashboard[0].depositWalletBalance,
                        interestWalletBalance: text1,
                        totalInvest: data.dashboard[0].totalInvest,
                        totalDeposit: data.dashboard[0].totalDeposit,
                        totalWithdraw: data.dashboard[0].totalWithdraw,
                        referralEarnings: data.dashboard[0].referralEarnings,
                    })
                }, 2000)
            }, 10000)


          })
          .catch(err => console.log(err))
  }, [loadData])
  if(sessionStorage.getItem("user") !== null) {
    return (
        <div className="bg-gray-200">
              <DashboardNav />
              <div style={{backgroundImage: `url(${base2})`}} className="p-20">
                <p className="font-semibold text-white text-3xl">Dashboard</p>
              </div>
              <div style={{backgroundImage: `url(${base})`}} className="lg:p-20 p-10 flex flex-col justify-between items-center">
                <div className="lg:hidden block">
                    <AdvancedRealTimeChart theme="dark" width={350}></AdvancedRealTimeChart>
                </div>
                <div className="lg:block hidden">
                    <AdvancedRealTimeChart theme="dark"></AdvancedRealTimeChart>
                </div>
                {/*<div className="border border-solid border-red-400 p-4 rounded-md w-full" id="emptyBalance">
                    <div className="flex flex-row justify-start items-center lg:space-x-10 space-x-2">
                        <i class="las la-exclamation-triangle text-red-400 la-2x"></i>
                        <div className="text-white">
                            <p className="font-semibold">Empty Balance</p>
                            <p className="text-white">Your balance is empty. Please make <Link to="/deposit" className="text-blue-800 hover:text-customYellow font-medium">deposit</Link> for your next investment.</p>
                        </div>
                    </div>
                </div>*/}
                {/*<div className="border border-solid border-customYellow p-4 rounded-md w-full mt-10">
                    <div className="flex flex-row justify-start items-center lg:space-x-10 space-x-2">
                        <i class="las la-user-lock text-customYellow la-2x"></i>
                        <div className="text-white">
                            <p className="font-semibold">2FA Authentication</p>
                            <p className="text-white">To keep safe your account, Please enable <Link className="text-blue-800 hover:text-customYellow font-medium">2FA</Link> security. It will make secure your account and balance.</p>
                        </div>
                    </div>
                </div>*/}
                <div className="flex lg:flex-row flex-col justify-between items-center lg:space-x-10 space-x-0 lg:space-y-0 space-y-10 mt-20">
                    <div className="bg-customYellow p-4 rounded-md lg:w-96 w-80">
                        <div className="flex flex-row justify-between items-center space-x-20">
                            <div>
                                <p className="text-black font-semibold">Deposit Wallet Balance</p>
                                <p className="text-white font-bold text-xl">${loadData.depositWalletBalance}</p>
                            </div>
                            <div className="p-4 rounded-md bg-black">
                                <i class="las la-dollar-sign text-customYellow la-2x"></i>
                            </div>
                        </div>
                    </div>
                    <div className="bg-customYellow p-4 rounded-md lg:w-96 w-80">
                        <div className="flex flex-row justify-between items-center space-x-20">
                            <div>
                                <p className="text-black font-semibold">Interest Wallet Balance</p>
                                <p className="text-white font-bold text-xl" ref={interest}><b className='text-red-900'>%</b>{loadData.interestWalletBalance}</p>
                            </div>
                            <div className="p-4 rounded-md bg-black">
                                <i class="las la-wallet text-customYellow la-2x"></i>
                            </div>
                        </div>
                    </div>
                    <div className="bg-customYellow p-4 rounded-md lg:w-96 w-80">
                        <div className="flex flex-row justify-between items-center space-x-20">
                            <div>
                                <p className="text-black font-semibold">Total Invest</p>
                                <p className="text-white font-bold text-xl">${loadData.totalInvest}</p>
                            </div>
                            <div className="p-4 rounded-md bg-black">
                                <i class="las la-cubes text-customYellow la-2x"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex lg:flex-row flex-col justify-between items-center lg:space-x-10 space-x-0 lg:space-y-0 space-y-10 mt-20">
                    <div className="bg-customYellow p-4 rounded-md lg:w-96 w-80">
                        <div className="flex flex-row justify-between items-center space-x-20">
                            <div>
                                <p className="text-black font-semibold">Total Deposit</p>
                                <p className="text-white font-bold text-xl">${loadData.totalDeposit}</p>
                            </div>
                            <div className="p-4 rounded-md bg-black">
                                <i class="las la-credit-card text-customYellow la-2x"></i>
                            </div>
                        </div>
                    </div>
                    <div className="bg-customYellow p-4 rounded-md lg:w-96 w-80">
                        <div className="flex flex-row justify-between items-center space-x-20">
                            <div>
                                <p className="text-black font-semibold">Total Withdraw</p>
                                <p className="text-white font-bold text-xl">{loadData.totalWithdraw}</p>
                            </div>
                            <div className="p-4 rounded-md bg-black">
                                <i class="las la-cloud-download-alt text-customYellow la-2x"></i>
                            </div>
                        </div>
                    </div>
                    <div className="bg-customYellow p-4 rounded-md lg:w-96 w-80">
                        <div className="flex flex-row justify-between items-center space-x-20">
                            <div>
                                <p className="text-black font-semibold">Referral Earnings</p>
                                <p className="text-white font-bold text-xl">{loadData.referralEarnings}</p>
                            </div>
                            <div className="p-4 rounded-md bg-black">
                                <i class="las la-user-friends text-customYellow la-2x"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-20 w-full lg:overflow-x-hidden overflow-x-scroll">
                    <table>
                      <thead className="bg-customYellow text-white">
                        <tr>
                          <th className="rounded-tl-md p-2">Date</th>
                          <th>Transaction ID</th>
                          <th>Amount</th>
                          <th>Wallet</th>
                          <th>Details</th>
                          <th className="rounded-tr-md p-2">Post Balance</th>
                        </tr>
                      </thead>
                      <tbody className="bg-black text-white shadow shadow-customYellow">
                        <tr>
                            <td className="px-10"></td>
                            <td className="px-32"></td>
                            <td className="px-20"></td>
                            <td className="px-10 py-4">No Transaction Found</td>
                            <td className="px-32"></td>
                            <td className="px-20"></td>
                        </tr>
                      </tbody>
                    </table>
                </div>
              </div>
              <Footer />
        </div>
      )
  } else {
    return(
        <div>
            <Navbar />
            <div style={{backgroundImage: `url(${base})`}} className="flex flex-col justify-between items-center">
                <p className="text-3xl font-medium text-customYellow my-20">Please Sign In or Register</p>
            </div>
            <Footer />
        </div>
    )
  }

}

export default Dashboard