import React from 'react'
import { Link } from 'react-router-dom'
import logo from "../img/logo.jpg"

const Navbar = () => {
  const show = () => {
    const sub = document.getElementById("sub")
    sub.classList.remove("hidden")
  }
  const close = () => {
    const sub = document.getElementById("sub")
    sub.classList.add("hidden")
  }
  const openNav = () => {
      const bars = document.querySelector("#bars")
      const close = document.querySelector("#close")
      const mobileNav = document.querySelector("#mobileNav")
      bars.classList.add("hidden")
      close.classList.remove("hidden")
      mobileNav.classList.remove("hidden")
    }
    const closeNav = () => {
      const bars = document.querySelector("#bars")
      const close = document.querySelector("#close")
      const mobileNav = document.querySelector("#mobileNav")
      bars.classList.remove("hidden")
      close.classList.add("hidden")
      mobileNav.classList.add("hidden")
    }
  return (
    <div className='bg-customBlack shadow lg:px-20 px-8'>
      <div className='flex flex-row justify-between items-center font-medium text-gray-200 py-5'>
        <div className='border-2 border-solid border-gray-400 rounded-md py-2 px-2'>
          {/* <img src={logo} alt="" width={100} /> */}
          <p className="text-customYellow">Activated Crypto</p>
        </div>
        <div className='lg:flex hidden flex-row justify-between items-center space-x-20'>
            <Link to="/" className='hover:text-customYellow'>Home</Link>
            <Link to="/about" className='hover:text-customYellow'>About Us</Link>
            <Link className='hover:text-customYellow' onMouseOver={show}>Legal</Link>
            <div id="sub" className="bg-zinc-900 rounded-md p-4 z-30 absolute top-16 left-96 flex flex-col space-y-4 hidden" onMouseLeave={close}>
                <Link to="/faqs" className='hover:text-customYellow'>FAQS</Link>
                <Link to="/terms" className='hover:text-customYellow'>TERMS AND CONDITIONS</Link>
            </div>
            <Link to="/contact" className='hover:text-customYellow'>Contact Us</Link>
            <Link to="/affiliate" className='hover:text-customYellow'>Affiliate</Link>
            <Link to="/register" className='hover:text-customYellow'>Register</Link>
            <Link to="/login" className='bg-customYellow rounded-full flex flex-row justify-between items-center space-x-2 text-customBlack py-2 px-4'>
              <i class="las la-user-plus"></i>
              <p className='hover:font-bold'>Sign In</p>
            </Link>
        </div>
        <div className='lg:hidden block text-customDarkBlue'>
          <i className='la la-bars la-2x hover:cursor-pointer hover:text-customYellow' id='bars' onClick={ openNav }></i>
          <i className='la la-times la-2x hidden hover:cursor-pointer hover:text-customYellow' id='close' onClick={ closeNav }></i>
        </div>
      </div>
      <div className='flex flex-col justify-between items-start space-y-5 pb-10 hidden text-white' id='mobileNav'>
            <Link to="/" className='hover:text-customYellow'>Home</Link>
            <Link to="/about" className='hover:text-customYellow'>About Us</Link>
            <Link to="/faqs" className='hover:text-customYellow'>Faqs</Link>
            <Link to="/terms" className='hover:text-customYellow'>Terms and Conditions</Link>
            <Link to="/contact" className='hover:text-customYellow'>Contact Us</Link>
            <Link to="/affiliate" className='hover:text-customYellow'>Affiliate</Link>
            <Link to="/register" className='hover:text-customYellow'>Register</Link>
            <Link to="/login" className='bg-customYellow rounded-full flex flex-row justify-between items-center space-x-2 text-customBlack py-2 px-4'>
              <i class="las la-user-plus"></i>
              <p className='hover:font-bold'>Sign In</p>
            </Link>
      </div>
    </div>
  )
}

export default Navbar