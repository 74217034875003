import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Navbar from './Navbar'
import Footer from './Footer'
import base from "../img/base.jpg"
import base2 from "../img/base2.png"
import base3 from "../img/base3.PNG"

const Login = () => {
  let navigate = useNavigate()
  const submit = (e) => {
    e.preventDefault()
    const form = new FormData(document.querySelector("#form"))
    let body = {}
    for(let key of form.keys()) {
        body[key] = form.get(key)
    }
      fetch("/api/login", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          "Accept": "application/json, text/plain, */*"
        },
        body: JSON.stringify(body)
      })
      .then((res) => {
        return res.json()
      })
      .then((data) => {
        if (data.user == null) {
          let error = document.querySelector("#error")
          error.classList.remove("hidden")
          setTimeout(() => {
            error.classList.add("hidden")
          }, 5000)
        } else {
            sessionStorage.setItem("user", JSON.stringify(data.user))
            sessionStorage.setItem("depositUser", data.user.username)
            navigate("/dashboard")
        }
      })
      .catch(err => console.log(err))
  }
  return (
    <div className="bg-gray-200">
          <Navbar />
          <div style={{backgroundImage: `url(${base2})`}} className="p-20">
            <p className="font-semibold text-white text-3xl">Login</p>
          </div>
          <div style={{backgroundImage: `url(${base})`}} className="p-20 flex flex-col justify-between items-center">
            { /* <img src={base3} alt="" className="border-2 border-solid border-customYellow lg:block hidden"/> */ }
            <div className="bg-black py-4 lg:px-48 px-0 rounded-md w-auto container mb-20 border-2 border-solid border-customYellow">
                <form action="" className="mt-4 lg:px-0 px-10" id="form">
                    <div className='bg-red-500 text-center p-2 rounded font-medium hidden mb-10 text-white' id="error">
                      Oops, no such user!
                    </div>
                    <div>
                        <p className="font-semibold text-slate-300 mb-3">Username<span className="text-red-700">*</span></p>
                        <input type="text" id="username" name="username" className="p-3 lg:w-96 w-80 bg-neutral-800 text-white rounded"/>
                    </div>
                    <div>
                        <p className="font-semibold text-slate-300 my-4">Password<span className="text-red-700">*</span></p>
                        <input type="text" id="password" name="password" className="p-3 lg:w-96 w-80 bg-neutral-800 text-white rounded"/>
                    </div>
                    <button onClick={submit} className="p-3 rounded font-semibold hover:font-bold bg-customYellow text-neutral-900 lg:w-full w-80 my-10">Login</button>
                    <div className="mt-4">
                        <p className="text-white">Don't have an account? <Link to="/register" className="text-blue-800 hover:text-customYellow">Register</Link></p>
                    </div>
                </form>
            </div>
          </div>
          <Footer />
    </div>
  )
}

export default Login