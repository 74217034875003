import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'

const Affiliate = () => {
  return (
    <div className="bg-gray-200">
          <Navbar />
          <h1 className="text-neutral-800 text-3xl font-semibold mt-10 mb-4 text-center">Our Affiliate <span className="text-red-700">Program</span></h1>
          <div className="bg-black py-4 lg:px-20 px-10 rounded-md flex flex-col justify-between items-start w-11/12 container mx-auto mb-20">
            <p className="font-medium text-slate-500 mb-3">The company's growth is difficult to imagine without the help of numerous customers and partners. Many of them have been cooperated with us before and know about our capabilities, they helped us in shaping of business strategy and bringing the first investment in trust management. Today, any of our clients can be our partner and even earn through affiliate link. After registration each user receives a unique affiliate link (which contains his username). You can use this link for registration of new investors. Just send this link to your friend via email. Once your friend clicks a link and signs up, he becomes your referral.</p>
            <p className="font-medium text-white mb-3">Every deposit of your referral will bring you a reward in the amount of 7% and $2 for Referral Registration.</p>
            <p className="font-medium text-slate-500">Affiliate commission is accrued automatically after addition of deposit. You can use this amount at your discretion - to withdraw it or use it for new deposit. To become an affiliate and start earning money, you don't even need to have your own active deposit. However, we remind you that the best evidence of successful investing is always a personal experience.</p>
          </div>
          <Footer />
    </div>
  )
}

export default Affiliate