import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import DashboardNav from './DashboardNav'
import Navbar from './Navbar'
import Footer from './Footer'
import base from "../img/base.jpg"
import base2 from "../img/base2.png"
import base3 from "../img/base3.PNG"

const Profile = () => {
  let navigate = useNavigate()
  const updateProfile = (e) => {
    e.preventDefault()
    let form = new FormData(document.querySelector("#form"))
    let body = {}
    for(let key of form.keys()) {
        body[key] = form.get(key)
    }
    body.oldUsername = JSON.parse(sessionStorage.getItem("user")).username
    body.oldPassword = JSON.parse(sessionStorage.getItem("user")).password
    fetch("/api/updateProfile", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          "Accept": "application/json, text/plain, */*"
        },
        body: JSON.stringify(body)
      })
      .then((res) => {
        return res.json()
      })
      .then((data) => {
        if(data.msg == "Exists") {
            let error = document.querySelector("#error")
            error.classList.remove("hidden")
            setTimeout(() => {
                error.classList.add("hidden")
            }, 5000)
        } else {
            sessionStorage.setItem("user", JSON.stringify(body))
            document.querySelector("#form").reset()
            window.location.href= "#top"
            let success = document.querySelector("#success")
            success.classList.remove("hidden")
            setTimeout(() => {
                success.classList.add("hidden")
            }, 5000)
        }
      })
      .catch(err => console.log(err))
  }
  if(sessionStorage.getItem("user") !== null) {
    return (
        <div className="bg-gray-200">
              <DashboardNav />
              <div style={{backgroundImage: `url(${base2})`}} className="p-20" id="top">
                <p className="font-semibold text-white text-3xl">Profile</p>
              </div>
              <div style={{backgroundImage: `url(${base})`}} className="p-20 flex flex-col justify-between items-center lg:overflow-x-scroll overflow-x-hidden">
                <div className='bg-green-500 text-center p-2 rounded font-medium hidden mb-10 text-white' id="success">
                    Profile Updated!
                </div>
                <div className="flex flex-col lg:flex-row justify-between items-center lg:space-x-10 space-x-0 lg:space-y-0 space-y-10">
                    <div className="bg-black py-10 lg:px-28 px-2 rounded-md w-auto container mb-20 border border-solid border-slate-400 text-white text-start">
                        <h1 className="text-xl font-semibold mb-5">{JSON.parse(sessionStorage.getItem("user")).firstname} {JSON.parse(sessionStorage.getItem("user")).lastname}</h1>
                        <div className="flex flex-row justify-between items-center space-x-20 border border-solid border-white p-3 rounded-t-md">
                            <p className="font-medium flex flex-row justify-between items-center space-x-4"><i class="las la-user text-customYellow"></i> <span>Username</span></p>
                            <p className="font-medium mr-4">{JSON.parse(sessionStorage.getItem("user")).username}</p>
                        </div>
                        <div className="flex flex-row justify-between items-center space-x-20 border-b border-r border-l border-solid border-white p-3 rounded-md rounded-t-none rounded-b-none">
                            <p className="font-medium flex flex-row justify-between items-center space-x-4"><i class="las la-envelope text-customYellow"></i> <span>Email</span></p>
                            <p className="font-medium mr-4">{JSON.parse(sessionStorage.getItem("user")).email}</p>
                        </div>
                        <div className="flex flex-row justify-between items-center space-x-20 border-b border-r border-l border-solid border-white p-3 rounded-md rounded-t-none rounded-b-none">
                            <p className="font-medium flex flex-row justify-between items-center space-x-4"><i class="las la-phone text-customYellow"></i> <span>Mobile</span></p>
                            <p className="font-medium mr-4">{JSON.parse(sessionStorage.getItem("user")).mobile}</p>
                        </div>
                        <div className="flex flex-row justify-between items-center space-x-20 border-b border-r border-l border-solid border-white p-3 rounded-md rounded-t-none">
                            <p className="font-medium flex flex-row justify-between items-center space-x-4"><i class="las la-globe text-customYellow"></i> <span>Country</span></p>
                            <p className="font-medium mr-4">{JSON.parse(sessionStorage.getItem("user")).country}</p>
                        </div>
                    </div>
                    <div className="bg-black py-10 lg:px-28 px-1 rounded-md lg:w-auto w-11/12 container mb-20 border border-solid border-slate-400">
                        <form action="" id="form">
                            <div className='bg-red-500 text-center p-2 rounded font-medium hidden my-10 text-white' id="error">
                              Oops, user already exists. Try using a different username.
                            </div>
                            <div className="flex lg:flex-row flex-col lg:space-y-0 space-y-4 lg:space-x-4 space-x-0 lg:px-0 px-6 justify-between lg:items-center items-start mb-4">
                                <div>
                                    <p className="font-semibold text-slate-500">First Name<span className="text-red-700">*</span></p>
                                    <input type="text" id="firstname" name="firstname" className="p-3 lg:w-auto w-72 rounded bg-neutral-800 text-white"/>
                                </div>
                                <div>
                                    <p className="font-semibold text-slate-500">Last Name<span className="text-red-700">*</span></p>
                                    <input type="text" id="lastname" name="lastname" className="p-3 lg:w-auto w-72 rounded bg-neutral-800 text-white"/>
                                </div>
                            </div>
                            <div className="flex lg:flex-row flex-col lg:space-y-0 space-y-4 lg:px-0 px-6 justify-between lg:items-center items-start mb-4">
                                <div>
                                    <p className="font-semibold text-slate-500">Country</p>
                                    <input type="text" id="country" name="country" className="p-3 rounded lg:w-auto w-72 bg-neutral-800 text-white"/>
                                </div>
                                <div>
                                    <p className="font-semibold text-slate-500">Password</p>
                                    <input type="text" id="password" name="password" className="p-3 rounded lg:w-auto w-72 bg-neutral-800 text-white"/>
                                </div>
                            </div>
                            <div className="flex lg:flex-row flex-col lg:space-y-0 space-y-4 lg:px-0 px-6 justify-between lg:items-center items-start mb-4">
                                <div>
                                    <p className="font-semibold text-slate-500">Email</p>
                                    <input type="text" id="email" name="email" className="p-3 rounded lg:w-auto w-72 bg-neutral-800 text-white"/>
                                </div>
                                <div>
                                    <p className="font-semibold text-slate-500">Mobile</p>
                                    <input type="text" id="mobile" name="mobile" className="p-3 rounded lg:w-auto w-72 bg-neutral-800 text-white"/>
                                </div>
                            </div>
                            <div className="flex flex-col justify-between items-center lg:space-x-3 space-x-0 mb-4">
                                <div className="lg:w-full w-96 lg:px-0 px-9">
                                    <p className="font-semibold text-slate-500">Username</p>
                                    <input type="text" id="username" name="username" className="p-3 lg:w-full w-72 rounded bg-neutral-800 text-white"/>
                                </div>
                            </div>
                            <div className="flex flex-col justify-between items-center">
                                 <button onClick={updateProfile} className="p-3 rounded font-semibold hover:font-bold bg-customYellow text-neutral-900 lg:w-full w-80 mt-7">Update Profile</button>
                            </div>
                        </form>
                    </div>
                </div>
              </div>
              <Footer />
        </div>
      )
  } else {
    return(
        <div>
            <Navbar />
            <div style={{backgroundImage: `url(${base})`}} className="flex flex-col justify-between items-center">
                <p className="text-3xl font-medium text-customYellow my-20">Please Sign In or Register</p>
            </div>
            <Footer />
        </div>
    )
  }
}

export default Profile