import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Affiliate from './components/Affiliate';
import Terms from './components/Terms';
import FAQS from './components/FAQS';
import Register from './components/Register';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Deposit from './components/Deposit';
import DepositHistory from './components/DepositHistory';
import DepositConfirm from './components/DepositConfirm';
import Withdraw from './components/Withdraw';
import WithdrawHistory from './components/WithdrawHistory';
import Investment from './components/Investment';
import MyInvestments from './components/MyInvestments';
import Profile from './components/Profile';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/affiliate" element={<Affiliate />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/faqs" element={<FAQS />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/deposit" element={<Deposit />} />
        <Route path="/deposit-history" element={<DepositHistory />} />
        <Route path="/deposit-confirm" element={<DepositConfirm />} />
        <Route path="/withdraw" element={<Withdraw />} />
        <Route path="/withdraw-history" element={<WithdrawHistory />} />
        <Route path="/investment" element={<Investment />} />
        <Route path="/my-investments" element={<MyInvestments />} />
        <Route path="/profile" element={<Profile />} />
      </Routes>
  </Router>
  );
}

export default App;
