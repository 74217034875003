import React from 'react'
import { Link } from 'react-router-dom'
import logo from "../img/logo.jpg"

const Footer = () => {
  return (
    <div>
          <div className="bg-customBlack flex lg:flex-row flex-col justify-around items-start text-white py-20 px-10 lg:space-y-0 space-y-20">
              <div id='mainObjectives'>
                  <div className='border-2 border-solid border-gray-400 rounded-md py-2 px-8'>
                   {/* <img src={logo} alt="" width={100} /> */}
                   <p className="text-customYellow">Activated Crypto</p>
                  </div>
                  <p className='font-medium text-gray-400 mt-7'>The main objectives of the <br /> project are to meet the <br /> needs of cryptocurrency <br /> projects and users for <br /> traditional services, and to <br /> provide access to <br /> investment product.</p>
              </div>
              <div id='usefulLink'>
                  <h1 className='text-xl font-semibold mb-7 text-gray-400'>Useful Link</h1>
                  <ul className="list-disc text-customYellow">
                    <li className="mb-3">
                        <Link to="https://cointelegraph.com/learn/what-is-a-cryptocurrency-a-beginners-guide-to-digital-money" className='mb-12 text-gray-400 hover:text-customYellow'>What is Bitcoin?</Link>
                    </li>
                    <li className="mb-3">
                        <Link to="https://www.investopedia.com/articles/investing/082914/basics-buying-and-investing-bitcoin.asp" className='mb-12 text-gray-400 hover:text-customYellow'>How to Buy Bitcoin?</Link>
                    </li>
                    <li className="mb-3">
                        <Link to="/register" className='mb-12 text-gray-400 hover:text-customYellow'>Create an Account</Link>
                    </li>
                    <li className="mb-3">
                        <Link to="/login" className='mb-12 text-gray-400 hover:text-customYellow'>Login</Link>
                    </li>
                  </ul>
              </div>
              <div id='internalPages'>
                  <h1 className='text-xl font-semibold mb-7 text-gray-400'>Internal Pages</h1>
                  <ul className="list-disc text-customYellow">
                    <li className="mb-3">
                        <Link to="/about" className='mb-12 text-gray-400 hover:text-customYellow'>About Us</Link>
                    </li>
                    <li className="mb-3">
                        <Link to="/contact" className='mb-12 text-gray-400 hover:text-customYellow'>Contact Us</Link>
                    </li>
                    <li className="mb-3">
                        <Link to="/terms" className='mb-12 text-gray-400 hover:text-customYellow'>Privacy Policy</Link>
                    </li>
                    <li className="mb-3">
                        <Link to="/faqs" className='mb-12 text-gray-400 hover:text-customYellow'>FAQs</Link>
                    </li>
                    <li className="mb-3">
                        <Link to="/affiliate" className='mb-12 text-gray-400 hover:text-customYellow'>Affiliate Program</Link>
                    </li>
                  </ul>
              </div>
              <div id='address'>
                  <h1 className='text-xl font-semibold mb-7 text-gray-400'>Address</h1>
                  <ul className="text-customYellow">
                    <li className="mb-3">
                        <Link className='mb-12 text-gray-400 hover:text-customYellow'>
                            <i class="las la-map"></i>
                            <span>&nbsp; 264 Banbury Road, Oxford, <br /> England, OX2 7DY</span>
                        </Link>
                    </li>
                    <li className="mb-3">
                        <Link className='mb-12 text-gray-400 hover:text-customYellow'>
                            <i class="las la-phone"></i>
                            <span>&nbsp; +44 7479392240</span>
                        </Link>
                    </li>
                    <li className="mb-3">
                        <Link className='mb-12 text-gray-400 hover:text-customYellow'>
                            <i class="las la-envelope"></i>
                            <span>&nbsp; info@activatedcrypto.com</span>
                        </Link>
                    </li>
                  </ul>
              </div>
          </div> 
          <div className='flex flex-col justify-between items-center p-8 bg-neutral-900'>
              <p className="text-gray-400">© 2022 <Link to="/" className="text-customYellow">Activated Crypto</Link>. All Rights Reserved.</p>
          </div>
    </div>
  )
}

export default Footer