import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'

const Contact = () => {
  return (
    <div className="bg-gray-200">
          <Navbar />
          <h1 className="text-neutral-800 text-3xl font-semibold mt-10 mb-4 text-center">Contact <span className="text-customYellow">Us</span></h1>
          <div className="bg-customBlack py-4 lg:px-20 px-10 rounded-md flex flex-col justify-between items-start w-11/12 container mx-auto mb-20">
            <p className="font-semibold text-white text-xl mt-4">Feel free to drop us a message.</p>
            <p className="font-semibold text-white">What can we help you with?</p>
            <div className="flex lg:flex-row flex-col justify-between items-center lg:space-x-28 space-x-0 my-10">
                <div className="w-full">
                    <form action="">
                        <select name="question" id="question" className="bg-neutral-800 p-3 text-gray-400 lg:w-full w-auto mb-4">
                            <option value="trading">Trading Question</option>
                            <option value="finance">Finance Question</option>
                            <option value="technical">Technical Question</option>
                        </select>
                        <div className="flex lg:flex-row flex-col justify-between lg:items-center items-start lg:space-x-3 space-x-0 lg:space-y-0 space-y-4 mb-4">
                            <input type="text" className="p-3 w-auto" placeholder="Name"/>
                            <input type="email" className="p-3 w-auto" placeholder="Email"/>
                        </div>
                        <textarea name="msg" id="msg" cols="30" rows="10" className="p-3 lg:w-full w-auto mb-4" placeholder="Message"></textarea> <br />
                        <button className="p-3 mb-10 rounded font-semibold hover:font-bold bg-customYellow text-white">Send</button>
                    </form>
                </div>
                <div className="bg-black rounded py-10 lg:pr-52 lg:pl-10 p-4 w-full">
                    <i class="las la-home text-customYellow la-2x"></i>
                    <h1 className="font-semibold mt-2 text-white">ADDRESS:</h1>
                    <p className="text-slate-500 font-medium mb-4">264 Banbury Road, Oxford, <br /> England, OX2 7DY</p>
                    <i class="las la-envelope text-customYellow la-2x"></i>
                    <h1 className="font-semibold mt-2 text-white">EMAIL ADDRESS:</h1>
                    <p className="text-customYellow font-medium">info@Activatedcrypto.com</p>
                </div>
            </div>
          </div>
          <Footer />
    </div>
  )
}

export default Contact