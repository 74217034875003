import React from 'react'
import { Link } from 'react-router-dom'
import logo from "../img/logo.jpg"

const DashboardNav = () => {
  const logout = (e) => {
    sessionStorage.clear()
    window.location.href = "/"
  }
  const openNav = () => {
      const bars = document.querySelector("#bars")
      const close = document.querySelector("#close")
      const mobileNav = document.querySelector("#mobileNav")
      bars.classList.add("hidden")
      close.classList.remove("hidden")
      mobileNav.classList.remove("hidden")
    }
    const closeNav = () => {
      const bars = document.querySelector("#bars")
      const close = document.querySelector("#close")
      const mobileNav = document.querySelector("#mobileNav")
      bars.classList.remove("hidden")
      close.classList.add("hidden")
      mobileNav.classList.add("hidden")
    }
  return (
    <div className='bg-customBlack shadow px-20'>
      <div className='flex flex-row justify-between items-center font-medium text-gray-200 py-5'>
        <div className='border-2 border-solid border-gray-400 rounded-md py-2 px-2'>
          {/* <img src={logo} alt="" width={100} /> */}
          <p className="text-customYellow">Activated Crypto</p>
        </div>
        <div className='lg:flex hidden flex-row justify-between items-center space-x-20'>
            <Link to="/dashboard" className='hover:text-customYellow'>Dashboard</Link>
            <Link to="/investment" className='hover:text-customYellow'>Investment</Link>
            <Link to="/deposit" className='hover:text-customYellow'>Deposit</Link>
            <Link to="/withdraw" className='hover:text-customYellow'>Withdraw</Link>
            <Link to="/profile" className='hover:text-customYellow'>Profile</Link>
            <button onClick={logout} className='bg-customYellow rounded-full flex flex-row justify-between items-center space-x-2 text-customBlack py-2 px-4'>
              <i class="las la-sign-out-alt"></i>
              <p className='hover:font-bold'>Sign Out</p>
            </button>
        </div>
        <div className='lg:hidden block text-customDarkBlue'>
          <i className='la la-bars la-2x hover:cursor-pointer hover:text-customYellow' id='bars' onClick={ openNav }></i>
          <i className='la la-times la-2x hidden hover:cursor-pointer hover:text-customYellow' id='close' onClick={ closeNav }></i>
        </div>
      </div>
      <div className='flex flex-col justify-between items-start space-y-5 pb-10 hidden text-white' id='mobileNav'>
        <Link to="/dashboard" className='hover:text-customYellow'>Dashboard</Link>
        <Link to="/investment" className='hover:text-customYellow'>Investment</Link>
        <Link to="/deposit" className='hover:text-customYellow'>Deposit</Link>
        <Link to="/withdraw" className='hover:text-customYellow'>Withdraw</Link>
        <Link to="/profile" className='hover:text-customYellow'>Profile</Link>
        <button onClick={logout} className='bg-customYellow rounded-full flex flex-row justify-between items-center space-x-2 text-customBlack py-2 px-4'>
          <i class="las la-sign-out-alt"></i>
          <p className='hover:font-bold'>Sign Out</p>
        </button>
      </div>
    </div>
  )
}

export default DashboardNav