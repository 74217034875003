import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'

const About = () => {
  return (
    <div className="bg-gray-200">
          <Navbar />
          <h1 className="text-neutral-800 text-3xl font-semibold mt-10 mb-4 text-center">What Is Activated Crypto</h1>
          <div className="bg-white py-4 lg:px-20 px-10 rounded-md flex flex-col justify-between items-start w-11/12 container mx-auto mb-20">
            <p className="font-medium text-gray-500"><span className="font-bold">Activated Crypto</span> is a distinctive investment company offering our investors access to high-growth investment opportunities in Bitcoin markets and other services. We implement best practices of trading & mining of Bitcoins through our operations, while offering flexibility in our investment plans. Our company benefits from an extensive network of global clients. At <span className="font-bold">Activated Crypto</span> campany, we emphasize on understanding our client’s requirement and providing suitable solutions to meet their investment criteria. Our aim is to utilize our expertise & knowledge which will benefit our clients and the users of our services. Our company believes that when a team outperforms expectations, excellence becomes a reality.</p>
            <ul className="list-disc my-4">
                <li>Our Company head office is located here " 264 Banbury Road, Oxford, England, OX2 7DY"</li>
            </ul>
          </div>
          <Footer />
    </div>
  )
}

export default About