import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import DashboardNav from './DashboardNav'
import Navbar from './Navbar'
import Footer from './Footer'
import base from "../img/base.jpg"
import base2 from "../img/base2.png"
import base3 from "../img/base3.PNG"

const Withdraw = () => {
   const msg = useRef()
   const submit = (e) => {
    e.preventDefault()
    msg.current.classList.remove("hidden")
    setTimeout(() => {
        msg.current.classList.add("hidden")
    }, 5000)
   }
  if(sessionStorage.getItem("user") !== null) {
    return (
        <div className="bg-gray-200">
              <DashboardNav />
              <div style={{backgroundImage: `url(${base2})`}} className="p-20">
                <p className="font-semibold text-white text-3xl">Withdraw Money</p>
              </div>
              <div style={{backgroundImage: `url(${base})`}} className="p-20 flex flex-col justify-between items-center">
                <div className="flex flex-row justify-end items-center mb-10">
                    <Link to="/withdraw-history" className="p-4 rounded-md bg-customYellow text-black font-medium hover:font-semibold">Withdraw History</Link>
                </div>
                <div ref={msg} className="p-4 rounded bg-green-800 text-green-400 border border-solid border-green-400 my-10 hidden">
                    <p>Thanks for withdrawing, we'll get back to you soon!</p>
                </div>
                <div className="bg-black py-4 px-10 rounded-md lg:w-7/12 w-80 container mb-20 border border-solid border-slate-400">
                    <form action="" className="mt-4">
                        <div>
                            <p className="font-semibold text-slate-300 mb-3">Method<span className="text-red-700">*</span></p>
                            <select id="country" name="country" className="p-3 w-full bg-neutral-800 text-white rounded">
                                <option value="">Select Gateway</option>
                                <option value="cashapp">Cash app</option>
                                <option value="zelle">Zelle</option>
                                <option value="paypal">Paypal</option>
                                <option value="venom">Venom</option>
                                <option value="creditcard">Credit card</option>
                                <option value="giftcard">Gift card</option>
                            </select>
                        </div>
                        <div>
                            <p className="font-semibold text-slate-300 my-4">Amount<span className="text-red-700">*</span></p>
                            <div className="flex flex-row justify-between items-center">
                                <input type="number" id="amount" name="amount" className="p-3 w-full bg-neutral-800 text-white rounded-tl-md rounded-bl-md"/>
                                <p className="p-3 bg-customYellow text-white rounded-tr-md rounded-br-md">USD</p>
                            </div>
                        </div>
                        <button onClick={submit} className="p-3 rounded font-medium hover:font-semibold bg-customYellow text-neutral-900 w-full my-10">Submit</button>
                    </form>
                </div>
              </div>
              <Footer />
        </div>
      )
  } else {
    return(
        <div>
            <Navbar />
            <div style={{backgroundImage: `url(${base})`}} className="flex flex-col justify-between items-center">
                <p className="text-3xl font-medium text-customYellow my-20">Please Sign In or Register</p>
            </div>
            <Footer />
        </div>
    )
  }

}

export default Withdraw