import React from 'react'
import { Link } from 'react-router-dom'
import DashboardNav from './DashboardNav'
import Navbar from './Navbar'
import Footer from './Footer'
import base from "../img/base.jpg"
import base2 from "../img/base2.png"
import base3 from "../img/base3.PNG"

const WithdrawHistory = () => {
  if(sessionStorage.getItem("user") !== null) {
    return (
        <div className="bg-gray-200">
              <DashboardNav />
              <div style={{backgroundImage: `url(${base2})`}} className="p-20">
                <p className="font-semibold text-white text-3xl">Withdraw Log</p>
              </div>
              <div style={{backgroundImage: `url(${base})`}} className="p-20 flex flex-col justify-between items-center">
                <div className="my-10 lg:w-full w-80 lg:overflow-x-hidden overflow-x-scroll">
                    <table>
                      <thead className="bg-customYellow text-white">
                        <tr>
                          <th className="rounded-tl-md p-2">Gateway | Transaction</th>
                          <th>Initiated</th>
                          <th>Amount</th>
                          <th>Conversion</th>
                          <th>Status</th>
                          <th className="rounded-tr-md p-2">Action</th>
                        </tr>
                      </thead>
                      <tbody className="bg-black text-white shadow shadow-customYellow">
                        <tr>
                            <td className="px-32"></td>
                            <td className="px-20"></td>
                            <td className="px-20 py-4">Data not found</td>
                            <td className="px-20"></td>
                            <td className="px-20"></td>
                            <td className="px-20"></td>
                        </tr>
                      </tbody>
                    </table>
                </div>
              </div>
              <Footer />
        </div>
      )
  } else {
    return(
        <div>
            <Navbar />
            <div style={{backgroundImage: `url(${base})`}} className="flex flex-col justify-between items-center">
                <p className="text-3xl font-medium text-customYellow my-20">Please Sign In or Register</p>
            </div>
            <Footer />
        </div>
    )
  }

}

export default WithdrawHistory