import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'

const FAQS = () => {
  const q1 = () => {
    const faq1 = document.getElementById("faq1")
    faq1.classList.toggle("hidden")
    faq1.previousElementSibling.lastElementChild.classList.toggle("hidden")
    faq1.previousElementSibling.firstElementChild.classList.toggle("hidden")
  }
  const q2 = () => {
    const faq2 = document.getElementById("faq2")
    faq2.classList.toggle("hidden")
    faq2.previousElementSibling.lastElementChild.classList.toggle("hidden")
    faq2.previousElementSibling.firstElementChild.classList.toggle("hidden")
  }
  const q3 = () => {
    const faq3 = document.getElementById("faq3")
    faq3.classList.toggle("hidden")
    faq3.previousElementSibling.lastElementChild.classList.toggle("hidden")
    faq3.previousElementSibling.firstElementChild.classList.toggle("hidden")
  }
  const q4 = () => {
    const faq4 = document.getElementById("faq4")
    faq4.classList.toggle("hidden")
    faq4.previousElementSibling.lastElementChild.classList.toggle("hidden")
    faq4.previousElementSibling.firstElementChild.classList.toggle("hidden")
  }
  const q5 = () => {
    const faq5 = document.getElementById("faq5")
    faq5.classList.toggle("hidden")
    faq5.previousElementSibling.lastElementChild.classList.toggle("hidden")
    faq5.previousElementSibling.firstElementChild.classList.toggle("hidden")
  }
  const q6 = () => {
    const faq6 = document.getElementById("faq6")
    faq6.classList.toggle("hidden")
    faq6.previousElementSibling.lastElementChild.classList.toggle("hidden")
    faq6.previousElementSibling.firstElementChild.classList.toggle("hidden")
  }
  const q7 = () => {
    const faq7 = document.getElementById("faq7")
    faq7.classList.toggle("hidden")
    faq7.previousElementSibling.lastElementChild.classList.toggle("hidden")
    faq7.previousElementSibling.firstElementChild.classList.toggle("hidden")
  }
  const q8 = () => {
    const faq8 = document.getElementById("faq8")
    faq8.classList.toggle("hidden")
    faq8.previousElementSibling.lastElementChild.classList.toggle("hidden")
    faq8.previousElementSibling.firstElementChild.classList.toggle("hidden")
  }
  const q9 = () => {
    const faq9 = document.getElementById("faq9")
    faq9.classList.toggle("hidden")
    faq9.previousElementSibling.lastElementChild.classList.toggle("hidden")
    faq9.previousElementSibling.firstElementChild.classList.toggle("hidden")
  }
  const q10 = () => {
    const faq10 = document.getElementById("faq10")
    faq10.classList.toggle("hidden")
    faq10.previousElementSibling.lastElementChild.classList.toggle("hidden")
    faq10.previousElementSibling.firstElementChild.classList.toggle("hidden")
  }
  return (
    <div className="bg-gray-200" id="top">
          <Navbar />
          <h1 className="text-neutral-800 text-3xl font-semibold mt-10 mb-4 text-center">Frequently <span className="text-customYellow">asked questions</span></h1>
          <div className="flex lg:flex-row flex-col justify-between items-center container mx-auto lg:space-y-0 space-y-10 my-20">
            <p className="rounded p-4 bg-customYellow font-medium text-white lg:w-96 w-11/12 text-center">General Questions.</p>
            <div className="bg-black p-4 rounded lg:w-1/2 w-11/12">
                <div className="mb-4">
                    <p className="rounded p-4 bg-customYellow font-medium text-white hover:cursor-pointer text-center" onClick={q1}>What is Activated Crypto? <i class="las la-angle-up"></i><i class="las la-angle-down hidden"></i></p>
                    <p id="faq1" className="rounded p-4 bg-neutral-800 text-slate-300 hidden">Activated Crypto is modern investment program where you are able to make deposit into any of our plans and earn profit return at the end of each contract duration. Our goal is to achieve the highest return from the activity on the foreign currency exchange (Forex) and Cryptocurrency exchange markets. Our company is constantly evolving, it improves its marketing components and creates new investment portfolio towards retirement income plans, real estate and emerging markets. All this makes us an industry leader with the capability to adapt to the constantly changing market conditions.</p>
                </div>
                <div className="mb-4">
                    <p className="rounded p-4 bg-customYellow font-medium text-white hover:cursor-pointer text-center" onClick={q2}>Do you accept clients from all over the world? <i class="las la-angle-up"></i><i class="las la-angle-down hidden"></i></p>
                    <p id="faq2" className="rounded p-4 bg-neutral-800 text-slate-300 hidden">As an international company we help traders from every corner of the world to increase their profits.</p>
                </div>
                <div className="mb-4">
                    <p className="rounded p-4 bg-customYellow font-medium text-white hover:cursor-pointer text-center" onClick={q3}>Who controls the investment portfolio? <i class="las la-angle-up"></i><i class="las la-angle-down hidden"></i></p>
                    <p id="faq3" className="rounded p-4 bg-neutral-800 text-slate-300 hidden">The investment portfolio is managed by a team of experienced financial specialists, of lawyers, professional trade analysts who have been working on the currency and stock exchange market for more than 10 years on average. Our experience and contacts ensure access for us to a wide range of local and global resources and bring about benefit from the world’s best and most effective technologies of trading on the Forex, Binary and Cryptocurrency market.</p>
                </div>
                <div className="mb-4">
                    <p className="rounded p-4 bg-customYellow font-medium text-white hover:cursor-pointer text-center" onClick={q4}>What is the risk for my investment? <i class="las la-angle-up"></i><i class="las la-angle-down hidden"></i></p>
                    <p id="faq4" className="rounded p-4 bg-neutral-800 text-slate-300 hidden">There is always a risk in every business. For example, in case of adverse global processes which are not under reasonable control of the company management. However, The company practices insuring all investments regardless of its sum. Currency Trading, Cryptocurrency Trading and start-up investing are among the riskiest forms of investments in the financial markets and therefore require sufficient skills and experience. We have a stabilization fund in place to protect Our clients from known trading risks.</p>
                </div>
                <div className="mb-4">
                    <p className="rounded p-4 bg-customYellow font-medium text-white hover:cursor-pointer text-center" onClick={q5}>Will you teach me how to trade? <i class="las la-angle-up"></i><i class="las la-angle-down hidden"></i></p>
                    <p id="faq5" className="rounded p-4 bg-neutral-800 text-slate-300 hidden">We don’t provide any particular training at the website, but our account managers do their best to help customers to have the best results possible.</p>
                </div>
                <div className="mb-4">
                    <p className="rounded p-4 bg-customYellow font-medium text-white hover:cursor-pointer text-center" onClick={q6}>How can I pay for an investment plan? <i class="las la-angle-up"></i><i class="las la-angle-down hidden"></i></p>
                    <p id="faq6" className="rounded p-4 bg-neutral-800 text-slate-300 hidden">At Activated Crypto, we do everything possible to simplify the interaction with the service and make it secure at the same time, thus we chose the most reliable ways for safest the fastest money transactions. We accept bitcoin payments.</p>
                </div>
                <div className="mb-4">
                    <p className="rounded p-4 bg-customYellow font-medium text-white hover:cursor-pointer text-center" onClick={q7}>What tax am I subjected to? <i class="las la-angle-up"></i><i class="las la-angle-down hidden"></i></p>
                    <p id="faq7" className="rounded p-4 bg-neutral-800 text-slate-300 hidden">Activated Crypto does not give tax advice. Please consult your tax or legal advisor.</p>
                </div>
                <div className="mb-4">
                    <p className="rounded p-4 bg-customYellow font-medium text-white hover:cursor-pointer text-center" onClick={q8}>How safe is it to use company website while making investment? <i class="las la-angle-up"></i><i class="las la-angle-down hidden"></i></p>
                    <p id="faq8" className="rounded p-4 bg-neutral-800 text-slate-300 hidden">Our company provided its investors with a website equipped with the latest and most effective means of data protection, antivirus software, EV SSL encryption protocol, protection from DDoS attacks, and located the website on powerful and reliable servers.</p>
                </div>
                <div className="mb-4">
                    <p className="rounded p-4 bg-customYellow font-medium text-white hover:cursor-pointer text-center" onClick={q9}>How long does it take to withdraw funds? <i class="las la-angle-up"></i><i class="las la-angle-down hidden"></i></p>
                    <p id="faq9" className="rounded p-4 bg-neutral-800 text-slate-300 hidden">Generally withdrawal requests are processed within 1-2 working days depending on the payment method.</p>
                </div>
                <div className="mb-4">
                    <p className="rounded p-4 bg-customYellow font-medium text-white hover:cursor-pointer text-center" onClick={q10}>I've lost my password, what should I do? <i class="las la-angle-up"></i><i class="las la-angle-down hidden"></i></p>
                    <p id="faq10" className="rounded p-4 bg-neutral-800 text-slate-300 hidden">
                        1. Click “Forgot password” button in the login screen.
                        <br />
                        2. Enter the email address you used to register your account with.
                        <br />
                        3. Log in to your email and check your inbox for the password reset email.
                        <br />
                        <br />
                        4. Follow the link in the email and enter a new password. The email may take up to 10 minutes to be delivered (make sure to check the spam folder if you don’t receive it.
                        <br />
                        Warning! Resetting the password will temporarily block withdrawals request for 24 hours due to security precautions.
                    </p>
                </div>
            </div>
          </div>
          <div style={{background: "linear-gradient(90deg, rgba(204,163,84,1) 80%, rgba(52,41,21,1) 100%)"}} className="flex flex-col lg:justify-between justify-center items-center py-20 lg:px-0 px-10">
            <h1 className="text-white text-4xl font-medium mb-4">Can't find your answer?</h1>
            <p className="text-white font-medium">Hit us on Whatsapp or Email us and we'll get back to you as soon as we can.</p>
            <button onClick={()=> window.location.href="#top"} className="rounded-md p-4 bg-white text-black hover:bg-customYellow hover:text-white hover:shadow-2xl flex flex-row justify-between items-center space-x-3 mt-10">
                <i class="las la-envelope-open la-2x"></i>
                <p className="font-medium">info@Activatedcrypto.com</p>
            </button>

          </div>
          <Footer />
    </div>
  )
}

export default FAQS