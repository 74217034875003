import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import DashboardNav from './DashboardNav'
import Navbar from './Navbar'
import Footer from './Footer'
import base from "../img/base.jpg"
import base2 from "../img/base2.png"
import base3 from "../img/base3.PNG"

const DepositConfirm = () => {
  const navigate = useNavigate()
  const submit = (e) => {
    setTimeout(() => {
        sessionStorage.setItem("uploaded", "true")
        navigate("/deposit")
    }, 3000)
  }
  if(sessionStorage.getItem("user") !== null) {
    return (
        <div className="bg-gray-200">
              <DashboardNav />
              <div style={{backgroundImage: `url(${base2})`}} className="p-20">
                <p className="font-semibold text-white text-3xl">Deposit Confirm</p>
              </div>
              <div style={{backgroundImage: `url(${base})`}} className="p-20 flex flex-col justify-between items-center">
                <div className="flex flex-row justify-between items-center space-x-10">
                    <div className="bg-black py-5 rounded-md lg:w-auto w-80 container mb-20 border border-solid border-slate-400 text-white text-start">
                        <h1 className="text-xl font-semibold mb-5 border-b border-solid border-slate-400 text-center pb-5"><i class="las la-wallet"></i> {JSON.parse(sessionStorage.getItem("deposit")).gateway} Payment</h1>
                        <div className="lg:px-20 px-10 text-center mt-10">
                            <p className="mb-6">You have requested <span className="text-green-400">{JSON.parse(sessionStorage.getItem("deposit")).amount} USD</span>, Please pay <span className="text-green-400">${JSON.parse(sessionStorage.getItem("deposit")).amount}</span> for successful payment</p>
                            <p className="mb-6">Please follow the instruction below</p>
                            <p className="mb-6">Make Your Deposit To This Bitcoin Wallet Address To Make Payment.</p>
                            <p className="mb-6 lg:text-2xl text-base font-medium">bc1q56cmnsh7sfqghsrkyz9q <br />7p4k2gnm60vdvyv3cv <i onClick={()=> navigator.clipboard.writeText("bc1q56cmnsh7sfqghsrkyz9q7p4k2gnm60vdvyv3cv")} class="las la-copy la-2x hover:cursor-pointer"></i></p>
                        </div>
                        <div className="px-10 mt-10">
                            <form action="/api/upload" className="mt-4" enctype="multipart/form-data" method="POST">
                                <div>
                                    <p className="font-semibold text-slate-300 mb-3">Upload Payment Proof<span className="text-red-700">*</span></p>
                                    <input type="file" id="proof" name="proof" className="p-3 w-full bg-neutral-800 text-white rounded-tl-md rounded-bl-md"/>
                                </div>
                                <div className="hidden">
                                    <input type="text" id="user" name="user" value={sessionStorage.getItem("user")} className="p-3 w-full bg-neutral-800 text-white rounded-tl-md rounded-bl-md"/>
                                </div>
                                <div className="hidden">
                                    <input type="text" id="details" name="details" value={sessionStorage.getItem("deposit")} className="p-3 w-full bg-neutral-800 text-white rounded-tl-md rounded-bl-md"/>
                                </div>
                                <button onClick={submit} className="p-3 rounded font-medium hover:font-semibold bg-customYellow text-neutral-900 w-full my-10">Pay Now</button>
                            </form>
                        </div>
                    </div>
                </div>

              </div>
              <Footer />
        </div>
      )
  } else {
    return(
        <div>
            <Navbar />
            <div style={{backgroundImage: `url(${base})`}} className="flex flex-col justify-between items-center">
                <p className="text-3xl font-medium text-customYellow my-20">Please Sign In or Register</p>
            </div>
            <Footer />
        </div>
    )
  }

}

export default DepositConfirm