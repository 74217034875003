import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import DashboardNav from './DashboardNav'
import Navbar from './Navbar'
import Footer from './Footer'
import base from "../img/base.jpg"
import base2 from "../img/base2.png"
import base3 from "../img/base3.PNG"
import base4 from "../img/bg-4.png"

const Investment = () => {
  let navigate = useNavigate()
  const showForm = (e) => {
    e.preventDefault()
    document.querySelector("#mask").classList.remove("hidden")
    document.querySelector("#root").classList.add("overflow-y-hidden")
    document.querySelector("#root").classList.add("max-h-screen")
    document.querySelector("#plan").innerText = `Confirm to invest on ${e.target.parentElement.firstElementChild.innerText}`
    document.querySelector("#investment").innerText = `Invest: ${e.target.previousElementSibling.innerText}`
    document.querySelector("#interest").innerText = `Interest: ${e.target.parentElement.firstElementChild.nextElementSibling.innerText.split(" ")[0]}`

    console.log(e.target.previousElementSibling.innerText)
  }
  const hideForm = (e) => {
    e.preventDefault()
    document.querySelector("#mask").classList.add("hidden")
    document.querySelector("#root").classList.remove("overflow-y-hidden")
    document.querySelector("#root").classList.remove("max-h-screen")
  }
  const submit = (e) => {
    e.preventDefault()
    const form = new FormData(document.querySelector("#form"))
    let body = {}
    for(let key of form.keys()) {
        body[key] = form.get(key)
    }
    sessionStorage.setItem("deposit", JSON.stringify(body))
    document.querySelector("#mask").classList.add("hidden")
    document.querySelector("#root").classList.remove("overflow-y-hidden")
    document.querySelector("#root").classList.remove("max-h-screen")
    navigate("/deposit-confirm")
  }
  if(sessionStorage.getItem("user") !== null) {
    return (
        <div id="root" className="bg-gray-200">
              <DashboardNav />
              <div id="mask" className="flex flex-col justify-between items-center absolute w-full hidden">
                <div id='overlay' className='absolute opacity-70 z-10 w-full bg-black h-full' onClick={hideForm}>
                </div>
                <div className="bg-black py-4 px-10 rounded-md w-auto container border border-solid border-slate-400 z-20">
                    <form id="form">
                        <div className="flex flex-row justify-end items-center">
                            <i class="las la-times-circle text-red-500 la-2x hover:cursor-pointer mb-4" onClick={hideForm}></i>
                        </div>
                        <div className="mb-4">
                            <h1 className="text-slate-300 font-medium text-xl mb-3" id="plan"></h1>
                            <p className="text-slate-300 font-medium text-base" id="investment">Invest: $150000.00 - $10000000.00</p>
                            <p className="text-slate-300 font-medium text-base" id="interest">Interest: 19.2%</p>
                            <p className="text-slate-300 font-medium text-base">Per 24 hours , 1 time</p>
                        </div>
                        <div>
                            <p className="font-semibold text-slate-300 mb-3">Select Wallet<span className="text-red-700">*</span></p>
                            <select id="gateway" name="gateway" className="p-3 w-full bg-neutral-800 text-white rounded">
                                <option value="">Select One</option>
                                <option value="Bitcoin">Bitcoin</option>
                                <option value="BNB">BNB</option>
                                <option value="Cardano (ADA)">Cardano (ADA)</option>
                                <option value="Cronos chain (CRO)">Cronos chain (CRO)</option>
                                <option value="Ethereum (ERC20)">Ethereum (ERC20)</option>
                                <option value="USDT (TRC20)">USDT (TRC20)</option>
                            </select>
                        </div>
                        <div>
                            <p className="font-semibold text-slate-300 my-4">Invest Amount<span className="text-red-700">*</span></p>
                            <div className="flex flex-row justify-between items-center">
                                <input type="number" id="amount" name="amount" className="p-3 w-full bg-neutral-800 text-white rounded-tl-md rounded-bl-md"/>
                                <p className="p-3 bg-customYellow text-white rounded-tr-md rounded-br-md">USD</p>
                            </div>
                        </div>
                        <button onClick={submit} className="p-3 rounded font-medium hover:font-semibold bg-customYellow text-neutral-900 w-full my-10">Submit</button>
                    </form>
                </div>
              </div>
              <div style={{backgroundImage: `url(${base2})`}} className="p-20">
                <p className="font-semibold text-white text-3xl">Investment Plan</p>
              </div>
              <div style={{backgroundImage: `url(${base})`}} className="p-20 flex flex-col justify-between items-center">
                <div className="flex flex-row justify-end items-center mb-10">
                    <Link to="/my-investments" className="p-4 rounded-md bg-customYellow text-black font-medium hover:font-semibold">My Investments</Link>
                </div>
                <div className="flex lg:flex-row flex-col justify-between items-center lg:space-x-10 space-x-0 lg:space-y-0 space-y-10">
                    <div style={{backgroundImage: `url(${base4})`}} className="p-4 rounded-md text-center border border-solid border-customYellow pb-10 lg:w-auto w-80">
                        <h1 className="text-3xl font-bold text-customYellow mb-10">Bronze PLAN</h1>
                        <p className="text-white font-medium mb-3">2.25% ROI</p>
                        <div className="bg-customYellow h-px mb-3"></div>
                        <p className="text-white font-medium mb-3">Return Every 24hrs</p>
                        <div className="bg-customYellow h-px mb-3"></div>
                        <p className="text-white font-medium mb-3">Total 2.25% + <span className="p-1 rounded-full bg-green-800 text-green-400 border border-solid border-green-400 text-sm">Capital</span></p>
                        <div className="bg-customYellow h-px mb-3"></div>
                        <p className="text-customYellow font-semibold text-xl my-10">$50.00 - $3,999.00</p>
                        <Link onClick={showForm} className="p-4 rounded-md bg-customYellow text-black font-medium hover:font-semibold mb-4">Invest Now</Link>
                    </div>
                    <div style={{backgroundImage: `url(${base4})`}} className="p-4 rounded-md text-center border border-solid border-customYellow pb-10 lg:w-auto w-80">
                        <h1 className="text-3xl font-bold text-customYellow mb-10">Silver PLAN</h1>
                        <p className="text-white font-medium mb-3">2.25% ROI</p>
                        <div className="bg-customYellow h-px mb-3"></div>
                        <p className="text-white font-medium mb-3">Return Every 24hrs</p>
                        <div className="bg-customYellow h-px mb-3"></div>
                        <p className="text-white font-medium mb-3">Total 4.5% + <span className="p-1 rounded-full bg-green-800 text-green-400 border border-solid border-green-400 text-sm">Capital</span></p>
                        <div className="bg-customYellow h-px mb-3"></div>
                        <p className="text-customYellow font-semibold text-xl my-10">$4,000.00 - $30,000.00</p>
                        <Link onClick={showForm} className="p-4 rounded-md bg-customYellow text-black font-medium hover:font-semibold mb-4">Invest Now</Link>
                    </div>
                    <div style={{backgroundImage: `url(${base4})`}} className="p-4 rounded-md text-center border border-solid border-customYellow pb-10 lg:w-auto w-80">
                        <h1 className="text-3xl font-bold text-customYellow mb-10">Gold PLAN</h1>
                        <p className="text-white font-medium mb-3">2.25% ROI</p>
                        <div className="bg-customYellow h-px mb-3"></div>
                        <p className="text-white font-medium mb-3">Return Every 24hrs</p>
                        <div className="bg-customYellow h-px mb-3"></div>
                        <p className="text-white font-medium mb-3">Total 6.75% + <span className="p-1 rounded-full bg-green-800 text-green-400 border border-solid border-green-400 text-sm">Capital</span></p>
                        <div className="bg-customYellow h-px mb-3"></div>
                        <p className="text-customYellow font-semibold text-xl my-10">$30,000.00 - $100,000.00</p>
                        <Link onClick={showForm} className="p-4 rounded-md bg-customYellow text-black font-medium hover:font-semibold mb-4">Invest Now</Link>
                    </div>
                    <div style={{backgroundImage: `url(${base4})`}} className="p-4 rounded-md text-center border border-solid border-customYellow pb-10 lg:w-auto w-80">
                        <h1 className="text-3xl font-bold text-customYellow mb-10">Long term plan</h1>
                        <p className="text-white font-medium mb-3">3.00% ROI</p>
                        <div className="bg-customYellow h-px mb-3"></div>
                        <p className="text-white font-medium mb-3">Return Every 24hrs</p>
                        <div className="bg-customYellow h-px mb-3"></div>
                        <p className="text-white font-medium mb-3">Total 90% + <span className="p-1 rounded-full bg-green-800 text-green-400 border border-solid border-green-400 text-sm">Capital</span></p>
                        <div className="bg-customYellow h-px mb-3"></div>
                        <p className="text-customYellow font-semibold text-xl my-10">$100,000.00 - $1,000,000.00</p>
                        <Link onClick={showForm} className="p-4 rounded-md bg-customYellow text-black font-medium hover:font-semibold mb-4">Invest Now</Link>
                    </div>
                </div>
                <div className="flex flex-col justify-between items-center space-x-10 mt-20">
                    <div style={{backgroundImage: `url(${base4})`}} className="p-4 rounded-md text-center border border-solid border-customYellow pb-10 lg:w-auto w-80">
                        <h1 className="text-3xl font-bold text-customYellow mb-10">Economies plan</h1>
                        <p className="text-white font-medium mb-3">19.20% ROI</p>
                        <div className="bg-customYellow h-px mb-3"></div>
                        <p className="text-white font-medium mb-3">Return Every 24hrs</p>
                        <div className="bg-customYellow h-px mb-3"></div>
                        <p className="text-white font-medium mb-3">Total 19.2% + <span className="p-1 rounded-full bg-green-800 text-green-400 border border-solid border-green-400 text-sm">Capital</span></p>
                        <div className="bg-customYellow h-px mb-3"></div>
                        <p className="text-customYellow font-semibold text-xl my-10">$150,000.00 - $10,000,000.00</p>
                        <Link onClick={showForm} className="p-4 rounded-md bg-customYellow text-black font-medium hover:font-semibold">Invest Now</Link>
                    </div>
                </div>
              </div>
              <Footer />
        </div>
      )
  } else {
    return(
        <div>
            <Navbar />
            <div style={{backgroundImage: `url(${base})`}} className="flex flex-col justify-between items-center">
                <p className="text-3xl font-medium text-customYellow my-20">Please Sign In or Register</p>
            </div>
            <Footer />
        </div>
    )
  }

}

export default Investment